import { useCallback, useEffect, useRef, useState } from 'react';
import { CreditWidget, CreditWidgetConfig } from 'askona-credit-widget';

export const useCreditWidget = (config: CreditWidgetConfig) => {
  const ref = useRef(null);
  const creditWidget = useRef<CreditWidget>();
  const [isLoading, setIsLoading] = useState(false);

  const mount = useCallback(async () => {
    if (creditWidget.current) return;

    setIsLoading(true);
    const { CreditWidget, injectFonts } = await import('askona-credit-widget');

    if (ref.current && creditWidget.current == null) {
      creditWidget.current = new CreditWidget(ref.current, config);
      injectFonts(document.head);
      creditWidget.current.mount();
    }

    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const unmount = useCallback(() => {
    if (creditWidget.current) {
      creditWidget.current.unmount();
    }
  }, []);

  useEffect(() => {
    mount();
    return () => unmount();
  }, [mount, unmount]);

  return { ref, isLoading };
};

const App = ({ config }: { config: CreditWidgetConfig | undefined }) => {
  const pathname = window.location.pathname.slice(1);

  const { ref, isLoading } = useCreditWidget({
    ...(pathname && { initialData: { orderId: pathname } }),
    CB_HOST: 'https://cb-stage.askona.ru:7794',
    CB_TOKEN: 'techAgent',
    CB_SIGNATURE: 'eeab5d87168b2ac355609e3845f84d35',
    creditSystemType: 7,
    callback: () => null,
    amountsInRubles: true,
    ...config,
  });
  return (
    <>
      <div ref={ref} style={{ height: '100%', display: 'flex' }} />
      {isLoading && <div>Идёт загрузка виджета...</div>}
    </>
  );
};

const AppWithConfig = () => {
  const [loading, setLoading] = useState(true);

  const settings = useRef<CreditWidgetConfig>();
  useEffect(() => {
    fetch(`/settingscb.json`)
      .then((res) => res.json())
      .catch(() => setLoading(false))
      .then((res) => {
        settings.current = res;
        setLoading(false);
      });
  }, []);

  return (
    <>
      {loading ? (
        <div
          style={{ height: '100vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          Загрузка...
        </div>
      ) : (
        <App config={settings.current} />
      )}
    </>
  );
};

export default AppWithConfig;
